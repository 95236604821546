.grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 75%;
    padding: 20px 0;
    box-sizing: border-box;
    gap: 20px;
    padding-bottom: 0;
}

.card {
    padding: 5px;
    border: 1px solid #E1E3E6;
    box-sizing: border-box;
    width: calc((100% - (20px * 2)) / 3);
    cursor: pointer;
    position: relative;
    display: flex;
    gap: 10px;
    background-color: lightgrey;
}

.pizzaImg {
    width: 50%;
}

.information {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.information span {
    font-weight: bold;
    font-size: 13px;
}

.controls {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.checkboxShowReport,
.checkboxStuffedCrust,
.checkboxFeatured {
    display: flex;
    align-items: center;
    gap: 5px;
}

.checkboxShowReport label,
.checkboxStuffedCrust label,
.checkboxFeatured label {
    font-size: 13px;
    font-weight: 400;
}

.separator {
    border-top: 1px solid #bfbfbf;
    width: 100%;
}

.checkboxFeatured {
    position: absolute;
    bottom: 10px;
    left: 10px;
}

.checkboxFeatured input {
    background-color: white;
}

.checkboxFeatured label {
    color: white;
    font-weight: bold;
    text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
}

.radioStandard {
    display: flex;
    align-items: center;
    gap: 5px;
}

.radioStandard label {
    font-size: 18px;
}

.standardCheckbox:checked {
    background-color: #70ad47 !important;
    border-color: #70ad47 !important;
}

.notToStandardCheckbox:checked {
    background-color: red !important;
    border-color: red !important;
}

.reportTitle {
    display: block;
    margin-bottom: 25px;
}


.inspectedPictureWrapper {
    width: fit-content;
    position: relative;
    display: flex;
}

.inspectedPicture {
    width: 800px;
}

.closeInspectedPictureButton {
    position: absolute;
    top: 0;
    right: 0;
    background: white;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}